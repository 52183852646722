import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { Else, If, Then } from 'react-if';
import cc from 'classcat';
import nookies from 'nookies';
import styles from './footer.module.sass';
import { useAuthStore } from '../../../../providers/RootStoreProvider';
import { Text, Anchor } from '../../../Text';
import { Icon } from '../../../Icon';
import { Button } from '../../../Button';
import { Input } from '../../../Input';
import { SuccessForm } from '../../../SuccessForm';
import { oldApi } from '../../../../lib/api';
import { YaAndGaFooter } from '../../../../lib/YaAndGaHelper';
import { Subscribe } from '../../../MainPage/Subscribe';
import { IconSVG } from '../../../SpriteSVG';
import { SubFormNewYear } from '../../../SubFormNewYear/SubFormNewYear';

const subscription = Yup.object().shape({
  email: Yup.string()
    .email('Проверьте правильность введенных данных')
    .trim()
    .required('Обязательное поле'),
  name: Yup.string()
    .min(2, 'Имя должно содержать больше 2 символов')
    .max(25, 'Имя не должно содержать больше 25 символов')
    .matches(/^[а-яА-ЯёЁa-zA-Z\s]+$/, 'Введите корректное имя')
    .trim()
    .required('Введите имя'),
});

const defaultTitle = (
  <>
    Подпишитесь на рассылку с лучшими предложениями <br /> и новостями
  </>
);

const defaultDesc = (
  <>
    И получите в подарок на почту файл с нашими советами перед тем <br /> как
    отправиться в круиз
  </>
);

export const SubscribeForm = ({
  title = defaultTitle,
  desc = defaultDesc,
  whiteButton = null,
  bottomGap = true,
  isMobile,
  emailMetrik,
  user,
}) => (
  <div
    className={cc([styles.subscription], {
      [styles.subsriptionGap]: bottomGap,
    })}
  >
    <div className={styles.subscript}>
      <Formik
        validationSchema={subscription}
        initialValues={{
          name: '',
          email: '',
          success: false,
        }}
        onSubmit={async ({ name, email }, { resetForm, setFieldValue }) => {
          try {
            await oldApi
              .post('/User/subscribe_mailbox', {
                MAIL_BOOK_ID: 69,
                user: name,
                email,
                user_id: user?.id || null,
                ym_cid: !user?.isManager
                  ? nookies.get(null)?._ym_uid || null
                  : null,
              })
              .then(() => {
                emailMetrik.length > 0 &&
                  window.ym(35441960, 'reachGoal', emailMetrik);
                YaAndGaFooter(
                  'online_departure_board_newsletter_subscription_form_submit',
                );
              });
            resetForm();
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
          } finally {
            setFieldValue('success', true);
          }
        }}
      >
        {({ values, isSubmitting }) => (
          <If condition={!values.success}>
            <Then>
              <Form>
                {title && (
                  <div className={styles.subscriptionOne}>
                    <Text variant="heading-3">{title}</Text>
                  </div>
                )}

                <div className={styles.subscriptionForm}>
                  <div className={styles.subscriptionInpput}>
                    <Input
                      placeholder="Имя"
                      id="name"
                      label="Имя"
                      onClick={() => {
                        YaAndGaFooter('click_field_name_in_form_subscribe');
                      }}
                    />
                  </div>
                  <div className={styles.subscriptionInpput}>
                    <Input
                      label="Почта"
                      id="email"
                      placeholder="Почта"
                      onClick={() => {
                        YaAndGaFooter('click_field_email_in_form_subscribe');
                      }}
                    />
                  </div>
                  {isMobile ? (
                    <div className={styles.subscriptionButton}>
                      <Button
                        type="submit"
                        size={isMobile ? 'podpis-lg' : 'subscriptionFooter'}
                        view={whiteButton ? 'white' : null}
                        disabled={isSubmitting}
                        onClick={() => {
                          YaAndGaFooter(
                            'click_button_subscribe_in_form_subscribe',
                          );
                          window.ym(35441960, 'reachGoal', 'best_offers');
                        }}
                      >
                        Подписаться
                      </Button>
                    </div>
                  ) : (
                    <Button
                      type="submit"
                      size={isMobile ? 'podpis-lg' : 'subscriptionFooter'}
                      view={whiteButton ? 'white' : null}
                      disabled={isSubmitting}
                      onClick={() => {
                        YaAndGaFooter(
                          'click_button_subscribe_in_form_subscribe',
                        );
                        window.ym(35441960, 'reachGoal', 'best_offers');
                      }}
                    >
                      Подписаться
                    </Button>
                  )}
                </div>
                <div className={styles.agreement}>
                  <Text>
                    Нажимая «Подписаться» вы принимаете{' '}
                    <a href="/personalData">
                      Политику обработки персональных данных
                    </a>{' '}
                    и даёте
                    <br /> согласие на обработку ваших данных
                  </Text>
                </div>
              </Form>
            </Then>
            <Else>
              <SuccessForm
                title="Все на борт! Подписка оформлена!"
                text="Проверьте на почте письмо от капитана. Там что-то интересное!"
              />
            </Else>
          </If>
        )}
      </Formik>
    </div>
  </div>
);

export const Footer = observer(
  ({ isMobile, withoutForm, isContact, isStep, emailMetrik, user, urlBF }) => {
    const store = useAuthStore();
    return (
      <footer
        className={cc([
          [styles.wrapper],
          {
            [styles.wrapperBlackFriday]: urlBF === 'black_friday',
          },
        ])}
      >
        {isContact ? (
          <div className={styles.subBlock}>
            <Subscribe user={user} />
          </div>
        ) : (
          <div className="container">
            {!withoutForm && !isStep && (
              <SubscribeForm
                isMobile={isMobile}
                emailMetrik={emailMetrik}
                user={user}
              />
            )}
          </div>
        )}
        {!isStep && !store.isB2bMode && (
          <div className="container">
            <div className={styles.innerWrapper}>
              <div className={styles.footerColum}>
                <ul className={styles.footerUL}>
                  <li className={styles.footerLI}>
                    <Anchor
                      href="/about"
                      rel="nofollow"
                      onClick={() => {
                        YaAndGaFooter('click_about_company_footer');
                      }}
                      className={cc([
                        [styles.text],
                        {
                          [styles.textBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      О компании
                    </Anchor>
                  </li>
                  <li className={styles.footerLI}>
                    <Anchor
                      href="/media"
                      onClick={() => {
                        YaAndGaFooter('click_media_about_us_footer');
                      }}
                      className={cc([
                        [styles.text],
                        {
                          [styles.textBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      СМИ о нас
                    </Anchor>
                  </li>
                  <li className={styles.footerLI}>
                    <Anchor
                      href="/discount"
                      onClick={() => {
                        YaAndGaFooter('click_lk_footer');
                      }}
                      className={cc([
                        [styles.text],
                        {
                          [styles.textBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Скидки и акции
                    </Anchor>
                  </li>
                </ul>
              </div>
              <div className={styles.footerColum}>
                <ul className={styles.footerUL}>
                  <li className={styles.footerLI}>
                    <Anchor
                      href="https://kruiz.online/blog"
                      onClick={() => {
                        YaAndGaFooter('click_blog_footer1');
                      }}
                      className={cc([
                        [styles.text],
                        {
                          [styles.textBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Блог
                    </Anchor>
                  </li>
                  <li className={styles.footerLI}>
                    <Anchor
                      href="/reviews"
                      onClick={() => {
                        YaAndGaFooter('click_reviews_footer');
                      }}
                      className={cc([
                        [styles.text],
                        {
                          [styles.textBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Отзывы
                    </Anchor>
                  </li>
                  <li className={styles.footerLI}>
                    <Anchor
                      href="/faq"
                      onClick={() => {
                        YaAndGaFooter('click_questions_and_answers_footer');
                      }}
                      className={cc([
                        [styles.text],
                        {
                          [styles.textBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Вопросы и ответы
                    </Anchor>
                  </li>
                </ul>
              </div>
              <div className={styles.footerColum}>
                <ul className={styles.footerUL}>
                  <li className={styles.footerLI}>
                    <Anchor
                      href="/politic"
                      onClick={() => {
                        YaAndGaFooter('click_politics_footer');
                      }}
                      className={cc([
                        [styles.text],
                        {
                          [styles.textBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Пользовательское соглашение
                    </Anchor>
                  </li>
                  <li className={styles.footerLI}>
                    <Anchor
                      href="/rent"
                      onClick={() => {
                        YaAndGaFooter('click_rent_ship_footer');
                      }}
                      className={cc([
                        [styles.text],
                        {
                          [styles.textBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Аренда теплохода
                    </Anchor>
                  </li>
                  <li className={styles.footerLI}>
                    <Anchor
                      href="/vendors"
                      onClick={() => {
                        YaAndGaFooter('click_rent_ship_footer');
                      }}
                      className={cc([
                        [styles.text],
                        {
                          [styles.textBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Наши партнеры
                    </Anchor>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        {!store.isB2bMode && (
          <>
            <div className="container">
              <div className={styles.secondFooter}>
                <div className={styles.number}>
                  <div className="header-phone-big">
                    <a
                      href="tel:84958775834"
                      onClick={() => {
                        YaAndGaFooter('click_phone_footer');
                      }}
                      className={cc([
                        [styles.text],
                        {
                          [styles.textBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      +7 495 877-58-34
                    </a>
                  </div>
                  <div className={styles.info}>
                    <Text variant="textBF">
                      ежедневно с 8:00 до 21:00 по МСК
                    </Text>
                  </div>
                </div>
                <div className={styles.footerSocial}>
                  <div className={styles.social}>
                    {/* <a
                      href="https://wa.me/79397100589"
                      rel="nofollow noreferrer"
                      onClick={() => {
                        YaAndGaFooter('click_whatsup_footer');
                      }}
                    >
                      <Icon
                        icon={
                          <IconSVG
                            name={'social/wa_mono'}
                            width={isMobile ? 40 : 32}
                            height={isMobile ? 40 : 32}
                          />
                        }
                        title="whatsapp"
                      />
                    </a> */}
                    <a
                      href="https://t.me/kruizonline"
                      rel="nofollow noreferrer"
                    >
                      <Icon
                        icon={
                          <IconSVG
                            name={'social/tg_mono'}
                            width={isMobile ? 40 : 32}
                            height={isMobile ? 40 : 32}
                          />
                        }
                        title="telegram"
                      />
                    </a>
                    {/* <a
                      className={styles.inst}
                      href="https://www.instagram.com/kruiz.online/"
                      target={isMobile ? '' : '_blank'}
                      rel="nofollow noreferrer"
                      onClick={() => {
                        YaAndGaFooter('click_ig_footer');
                      }}
                    >
                      <Icon
                        icon={
                          <IconSVG
                            name={'social/inst_mono'}
                            width={isMobile ? 40 : 32}
                            height={isMobile ? 40 : 32}
                          />
                        }
                        title="instagram"
                      />
                      <div className={styles.starWrapper}>*</div>
                    </a> */}
                    <a
                      href="https://vk.com/kruiz.online"
                      rel="nofollow noreferrer"
                      onClick={() => {
                        YaAndGaFooter('click_vk_footer');
                      }}
                    >
                      <Icon
                        icon={
                          <IconSVG
                            name={'social/vk_mono'}
                            width={isMobile ? 40 : 32}
                            height={isMobile ? 40 : 32}
                          />
                        }
                        title="Вконтакте"
                      />
                    </a>
                    <a
                      href="https://ok.ru/group/52494082834611"
                      rel="nofollow noreferrer"
                    >
                      <Icon
                        icon={
                          <IconSVG
                            name={'social/ok_mono'}
                            width={isMobile ? 40 : 32}
                            height={isMobile ? 40 : 32}
                          />
                        }
                        title="ok"
                      />
                    </a>
                    <a
                      href="https://zen.yandex.ru/id/5cf7c5fd92015300af0beb57"
                      rel="nofollow noreferrer"
                    >
                      <Icon
                        icon={
                          <IconSVG
                            name={'social/dzen_mono'}
                            width={isMobile ? 40 : 32}
                            height={isMobile ? 40 : 32}
                          />
                        }
                        title="dzen.yandex"
                      />
                    </a>
                    {!isMobile && (
                      <Anchor
                        className={styles.blogCompany}
                        weight="800"
                        href="https://kruiz.online/blog"
                        variant={
                          urlBF === 'black_friday'
                            ? 'linkAsbuttonMoreBF'
                            : 'linkAsbuttonMore'
                        }
                        onClick={() => {
                          YaAndGaFooter('click_blog_footer2');
                        }}
                      >
                        Блог
                      </Anchor>
                    )}
                  </div>

                  {isMobile && (
                    <Anchor
                      className={styles.blogCompany}
                      weight="800"
                      href="https://kruiz.online/blog"
                      variant={
                        urlBF === 'black_friday'
                          ? 'linkAsbuttonMoreBF'
                          : 'linkAsbuttonMore'
                      }
                      style={{ padding: '6px 12px' }}
                      onClick={() => {
                        YaAndGaFooter('click_blog_footer2');
                      }}
                    >
                      Блог
                    </Anchor>
                  )}
                  {/* <div className={styles.descriptionStyle}>
                    *Соцсеть признана экстремистской и запрещена в России
                  </div> */}
                </div>
                <div className={styles.paymentMethods}>
                  <a
                    className={styles.paymentMethodTinkoff}
                    href="https://www.tbank.ru/"
                    rel="nofollow noreferrer"
                  >
                    {urlBF === 'black_friday' ? (
                      <Icon
                        leftGap={10}
                        icon={
                          <IconSVG
                            name={'payment/tbankBf'}
                            width={92}
                            height={28}
                          />
                        }
                        title="ТБанк"
                      />
                    ) : (
                      <Icon
                        leftGap={10}
                        icon={
                          <IconSVG
                            name={'payment/tbank'}
                            width={92}
                            height={28}
                          />
                        }
                        title="ТБанк"
                      />
                    )}
                  </a>
                  <a
                    className={styles.paymentMethodSbp}
                    href="https://sbp.nspk.ru/"
                    rel="nofollow noreferrer"
                  >
                    {urlBF === 'black_friday' ? (
                      <Icon
                        leftGap={10}
                        icon={
                          <IconSVG
                            name={'payment/SBPBF'}
                            width={78}
                            height={40}
                          />
                        }
                        title="СБП"
                      />
                    ) : (
                      <Icon
                        leftGap={10}
                        icon={
                          <IconSVG
                            name={'payment/SBP'}
                            width={78}
                            height={40}
                          />
                        }
                        title="СБП"
                      />
                    )}
                  </a>
                </div>
              </div>
            </div>

            <div className={styles.footerCompany}>
              <div>
                <a href="https://sk.ru" rel="noreferrer" target="_blank">
                  <img src="/promotion/skolkovo.svg" alt="" />
                </a>
              </div>
              <div>
                <Text variant="innogrn">
                  © 2016-{new Date().getFullYear()}, ООО "Круиз.онлайн", ИНН
                  6315008371, ОГРН 1166313053048 <br />
                  443001, Самара, ул. Галактионовская 157, этаж 12
                  <br />
                  129090, Торгово-офисный центр GARDEN MIR, проспект Мира, д.
                  40, 8 этаж, офис 804, Мещанский район, Москва
                </Text>
              </div>
            </div>
          </>
        )}
      </footer>
    );
  },
);
