// проверка на начало и конец НГ
export const isNewYear = () => {
  const date = new Date();

  const startDatePromotion = new Date(1733691600000);
  const endDatePromotion = new Date(1736715600000);
  // console.log('startDatePromotion', startDatePromotion);
  // console.log('endDatePromotion', endDatePromotion);

  return date >= startDatePromotion && date <= endDatePromotion;
};

export const genderHoliday = () => {
  const date = new Date();

  const startDatePromotion = new Date(1740949200000);
  const endDatePromotion = new Date(1741553999000);
  // console.log('startDatePromotion', startDatePromotion);
  // console.log('endDatePromotion', endDatePromotion);

  return date >= startDatePromotion && date <= endDatePromotion;
};
