export const logger = ({
  app = 'next',
  env = process.env.NODE_ENV,
  level = 'info',
  message,
  ym_cid = null,
  additionalFields = { typeEnv: null, endpoint: null, type: 'all' },
  error = null,
  stacktrace = null,
  line_number = null,
}) => {
  const logObject = {
    '@timestamp': new Date().toISOString(),
    app,
    env,
    level,
    message,
    ym_cid,
    ...additionalFields,
    error: error || null,
    stacktrace: stacktrace || null,
    line_number: line_number || null,
  };

  const filteredLogObject = Object.keys(logObject)
    .filter(i => logObject[i] !== null)
    .reduce((obj, key) => {
      obj[key] = logObject[key];
      return obj;
    }, {});

  Object.keys(filteredLogObject).forEach(key => {
    if (typeof filteredLogObject[key] !== 'object') {
      filteredLogObject[key] = String(filteredLogObject[key]);
    }
  });

  if (env === 'production') {
    console.log(JSON.stringify(filteredLogObject));
  } else {
    console.log(filteredLogObject);
  }
};
