import React, { memo, useCallback } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { Case, Default, Switch } from 'react-if';
import cc from 'classcat';
import { Text } from '../Text';
import styles from './input.module.sass';
// import ClearAdminInputIcon from '../../public/admin/icons/clean-input.svg';
// import OpenDropDownAdminIcon from '../../public/admin/icons/dropdown-arrow.svg';
import { Icon } from '../Icon';
import { IconSVG } from '../SpriteSVG';
// import { YaAndGaSearchLine } from '../../lib/YaAndGaHelper';

/**
 * Инпут.
 * Сделан на основе <Field /> формика
 */
export const Input = memo(
  ({
    label,
    id,
    key,
    error,
    readOnly,
    control,
    actionOnClick,
    dropdownIndicator,
    clearValue,
    last,
    noBoldLabel,
    disabled,
    noEdite,
    view,
    onClickField,
    isModalDrop,
    noAnimateLabel = false,
    onClearButtonClick,
    isLoading,
    ref,
    autoFocus,
    url,
    ...props
  }) => {
    const { values, setFieldValue, initialValues } = useFormikContext();
    const hasValue = () => values && !!values[id]?.length;
    const canCancel = clearValue !== undefined && hasValue();
    const isLabel = label !== '' && typeof label === 'string';
    const isCompact = view === 'compact';
    const isLandingView = view === 'landing';
    const isAdminView = view === 'admin';
    const isWideField = view === 'short';

    const RenderFilter = useCallback(
      (...ctx) => {
        if (actionOnClick && !noEdite) {
          actionOnClick({
            ...ctx,
            id,
            error,
            control,
          });
          return;
        }
        // В основном для метрик
        if (onClickField) onClickField();
      },
      [actionOnClick, noEdite],
    );

    const toInitialValueByType = initValue =>
      Array.isArray(initValue) ? [] : '';

    return (
      <div
        className={cc([
          styles[view],
          { [styles.noAnimateLabel]: noAnimateLabel },
        ])}
      >
        <div
          className={cc([
            typeof dropdownIndicator === 'boolean' && isCompact
              ? styles.fieldCompact
              : styles.field,
            {
              [styles.fieldReadOnly]: readOnly,
              [styles.fieldNoEdite]: noEdite,
              [styles.last]: last,
              [styles.disabled]: disabled,
            },
          ])}
        >
          <Field
            key={key}
            className={cc([
              {
                [styles.fieldControl]: !isCompact,
                [styles.fieldControlWideField]: isWideField,
                [styles.fieldError]: error,
                [styles.fieldNoError]: !error,
                [styles.free]: !canCancel,
                [styles.compactControl]: isCompact,
                [styles.symmetrical]: !isLabel,
              },
            ])}
            placeholder={label}
            as={control}
            readOnly={(readOnly && 'readonly') || noEdite}
            name={id}
            id={id}
            autoFocus={autoFocus}
            onClick={({ ...ctx }) => {
              RenderFilter(ctx);
            }}
            disabled={disabled}
            ref={ref}
            {...props}
          />
          {isLabel && (
            <label
              className={cc([
                {
                  [styles.fieldLabel]: !isCompact,
                  [styles.compactLabel]: isCompact,
                  [styles.fieldLabelnoBoldLabel]: noBoldLabel,
                  [styles.hide]: isCompact && canCancel,
                },
              ])}
              htmlFor={label}
            >
              {label}
            </label>
          )}
          <div
            className={!isCompact ? styles.fieldAction : styles.compactAction}
          >
            {isLoading && (
              <div className={styles.loading}>
                <Icon
                  icon={
                    <IconSVG name={'common/loader-s'} width={24} height={24} />
                  }
                  width={24}
                  height={24}
                  title="Loading"
                />
              </div>
            )}
            {canCancel && !disabled && (
              <button
                aria-label="Очистить поле"
                type="button"
                className={cc([
                  styles.fieldClear,
                  { [styles.fieldClearWideField]: isWideField },
                ])}
                onClick={async () => {
                  // Проверка для уникальных случаев
                  // Если поле есть и не булин
                  if (
                    clearValue !== undefined &&
                    typeof clearValue !== 'boolean'
                  ) {
                    setFieldValue(id, clearValue);
                    return;
                  }
                  await setFieldValue(
                    id,
                    toInitialValueByType(initialValues[id]),
                  );
                  await onClearButtonClick?.();
                }}
              >
                <Icon
                  className={cc({ [styles.hide]: !isAdminView })}
                  icon={
                    <IconSVG
                      name={'common/clean-input'}
                      width={24}
                      height={24}
                    />
                  }
                  title=""
                />
              </button>
            )}
            {typeof dropdownIndicator === 'boolean' && (
              <div
                className={cc([
                  styles.fieldArrowBtn,
                  {
                    [styles.compactArrowBtn]: isCompact,
                    [styles.active]: dropdownIndicator,
                    [styles.landing]: isLandingView,
                    [styles.fieldReadOnly]: readOnly || noEdite,
                    [styles.black]: url === 'black_friday',
                  },
                ])}
                aria-label="Удалить"
                role="button"
                tabIndex="0"
                onKeyPress={() => {
                  if (actionOnClick && !disabled) actionOnClick();
                }}
                onClick={() => {
                  if (actionOnClick && !disabled) actionOnClick();
                }}
              >
                <Switch>
                  <Case condition={isAdminView}>
                    <Icon
                      className={cc({ [styles.hide]: !isAdminView })}
                      icon={
                        <IconSVG
                          name={'common/dropdown-arrow'}
                          width={14}
                          height={8}
                        />
                      }
                      title=""
                    />
                  </Case>
                  {!isModalDrop && (
                    <Default>
                      {url === 'black_friday' ? (
                        <div
                          className={
                            !isCompact ? styles.fieldArrow : styles.compactArrow
                          }
                        />
                      ) : (
                        <div
                          className={
                            !isCompact ? styles.fieldArrow : styles.compactArrow
                          }
                        />
                      )}
                    </Default>
                  )}
                </Switch>
              </div>
            )}
          </div>
        </div>
        <ErrorMessage name={id}>
          {msg => (
            <Text topGap={6} colorText="error">
              {msg}
            </Text>
          )}
        </ErrorMessage>
      </div>
    );
  },
);

Input.defaultProps = {
  control: null,
  readOnly: false,
  noEdite: false,
  dropdownIndicator: null,
  actionOnClick: null,
  error: undefined,
  clearValue: false,
  noBoldLabel: false,
  label: '',
  view: null,
};
